<template>
  <div>
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
      :min-width="computedComponentName === 'v-menu' ? 100 : null"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-between filter-title white px-4 py-3 rounded"
          :class="{ 'font-weight-bold': isFilterApplied }"
          v-on="on"
          v-bind="attrs"
        >
          <div class="d-flex align-center flex-grow-1">
            <v-icon :color="isThemeLight ? 'black' : null" left>
              currency_rupee
            </v-icon>
            Cost
          </div>

          <v-icon class="ml-3">
            expand_more
          </v-icon>
        </div>
      </template>
      <v-card color="white">
        <v-card-text
          class="pt-md-3"
          :class="{ 'black--text': isThemeLight }"
        >
          <div class="mb-1 d-flex">
            <div class="d-flex align-center flex-grow-1">
              <v-icon
                :class="{ 'black--text': isThemeLight }"
                class="mr-1"
                left
              >
                currency_rupee
              </v-icon>

              Cost
            </div>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="isThemeLight ? 'black' : null"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>

              <span>
                Identify influencers by their costs.
              </span>
            </v-tooltip>
          </div>

          <div class="contain-select-width mt-4">
            <v-select
              v-model="form.for"
              :items="forOptions"
              dense
              outlined
              hide-details
              label="For"
            ></v-select>
          </div>

          <cost-selector
            class="mt-3"
            v-model="form.from"
            label="From"
            :max-value="maxValue + 1"
            @change="submitForm"
          />

          <cost-selector
            class="mt-3"
            v-model="form.to"
            label="To"
            @change="submitForm"
            :min-value="form.from"
            :disabled="isToDisabled"
          />
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
// Import the helper object
import costKeyMap from "@/helpers/costKeyMap.js"

// Import the block level component for selecting values
const CostSelector = () => import(/* webpackChunkName: "cost-selector" */ "@/blocks/common/selectors/CostSelector.vue")

// used throughout the code
const filterName = "cost"

const maxValue = 1_000_000

const originalForm = () => ({
  for: null,
  from: "500",
  to: Infinity.toString()
})

// Export the SFC
export default {
  // Name of the component
  name: "FilterCost",

  // Accept incoming data from parent
  props: {
    platform: {
      type: String,
      required: true
    }
  },

  // Register the children components
  components: {
    CostSelector,
  },

  // Define local data variables
  data: () => ({
    maxValue,
    form: originalForm(),

    shouldShowModal: false,
  }),

  // Computable data variables
  computed: {
    /**
     * Get the select input options
     *
     * @returns {Array}
     */
    forOptions() {
      return Object.keys(costKeyMap[this.platform]).map((key) => ({
        text: costKeyMap[this.platform][key],
        value: key
      }))
    },

    /**
     * All the filters that have been applied
     *
     * @returns {Array}
     */
    appliedFilters() {
      return this.$store.getters[`campaignPlanning/findFiltersByType`](filterName)
    },

    /**
     * Used to bold the filter name text
     *
     * @returns {Boolean}
     */
    isFilterApplied() {
      return Boolean(this.appliedFilters.length)
    },

    /**
     * In case the from value is the max, to must be disabled
     *
     * @returns {Boolean}
     */
    isToDisabled() {
      return parseInt(this.form.from, 10) === maxValue
    },

    /**
     * Show the filter as a dialog for mobile dialogs
     *
     * @returns {String}
     */
    computedComponentName() {
      return this.isMobileDevice ? "v-dialog" : "v-menu"
    },
  },

  // The vuelidate validations
  validations: {
    form: {}
  },

  methods: {
    /**
     * Takes an item and generates the text based on kind and weight
     */
    computeFilterText() {
      return costKeyMap[this.platform][this.form.for] + ": " +
        (this.isToDisabled || this.form.to === Infinity.toString() ? `>${nFormatter(this.form.from)}` : `${nFormatter(this.form.from)}-${nFormatter(this.form.to)}`)
    },

    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     *
     * @param {String} kind | Either "audience" or "influencer"
     */
    async submitForm() {
      await this.$v.form.$touch()

      if (this.$v.form.$anyError) return

      // if to is to be shown but to's value is falsy
      if (!this.isToDisabled && !this.form.to) {
        this.form.to = maxValue.toString()
      }

      // If there's some value, then update the filter
      const data = {
        type: filterName,
        data: {
          color: "teal lighten-5",
          icon: "currency_rupee",
          iconColor: "teal",
          text: this.computeFilterText(),
          inputs: {
            for: this.form.for,
            from: this.form.from,
            to: this.isToDisabled ? null : this.form.to
          }
        }
      }

      // Check if there's a filter already for this "for" value
      for (const appliedFilter of this.appliedFilters) {
        // If found
        if (appliedFilter.data.inputs.for === this.form.for) {
          // Remove it
          this.$store.dispatch("campaignPlanning/removeFilter", appliedFilter.id)
        }
      }

      // Now add this filter
      this.$store.dispatch("campaignPlanning/addFilter", data)
    },
  }
}
</script>

<style lang="stylus" scoped>
.contain-select-width
  min-width 12em
  width 12em
</style>
